/*-----------------------------------------------
    Generated by SwaggerToTS
    Do not customize this file, update the generator
    Copyright C Space 2024
-----------------------------------------------*/
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import { appId } from '../azure/auth';
import {
    getAuthToken,
    mediaApiScope,
    mediaApiUri
} from '../azure/auth';
import {
    MediaDetailModel,
} from './MediaApiTypes';

export async function userProfileUpload(userId: string): Promise<MediaDetailModel> { 
    const token = await getAuthToken([mediaApiScope]);
    const resp = await axios.post<MediaDetailModel>(
        `${mediaApiUri}/User/profile/${userId}`,
            {},
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

export async function userProfileDownload(userId: string): Promise<Blob> { 
    const token = await getAuthToken([mediaApiScope]);
    
    const resp = await axios.get<Blob>(
        `${mediaApiUri}/User/profile/${userId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

export async function userProfileDelete(userId: string): Promise<void> { 
    const token = await getAuthToken([mediaApiScope]);
    const resp = await axios.delete(
        `${mediaApiUri}/User/profile/${userId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

export async function userProfileMove(fromUserId: string, toUserId: string): Promise<void> { 
    const token = await getAuthToken([mediaApiScope]);
    const resp = await axios.post(
        `${mediaApiUri}/User/profile/${fromUserId}/moveTo/${toUserId}`,
            {},
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

export async function currentUserProfileUpload(): Promise<MediaDetailModel> { 
    const token = await getAuthToken([mediaApiScope]);
    const resp = await axios.post<MediaDetailModel>(
        `${mediaApiUri}/User/profile`,
            {},
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

export async function currentUserProfileDownload(): Promise<Blob> { 
    const token = await getAuthToken([mediaApiScope]);
    
    const resp = await axios.get<Blob>(
        `${mediaApiUri}/User/profile`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

export async function teamMemberHeroCopy(clientId: string, accountId: string, userId: string): Promise<MediaDetailModel> { 
    const token = await getAuthToken([mediaApiScope]);
    const resp = await axios.post<MediaDetailModel>(
        `${mediaApiUri}/User/TeamMemberHero/${clientId}/${accountId}/${userId}`,
            {},
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

export async function teamMemberHeroUpload(clientId: string, accountId: string): Promise<MediaDetailModel> { 
    const token = await getAuthToken([mediaApiScope]);
    const resp = await axios.post<MediaDetailModel>(
        `${mediaApiUri}/User/TeamMemberHero/${clientId}/${accountId}`,
            {},
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

export async function teamMemberHeroDownload(clientId: string, accountId: string, mediaId: string): Promise<Blob> { 
    const token = await getAuthToken([mediaApiScope]);
    
    const resp = await axios.get<Blob>(
        `${mediaApiUri}/User/TeamMemberHero/${clientId}/${accountId}/${mediaId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}` 
        } });
    return resp.data;
}

export async function deleteTeamMemberHero(clientId: string, accountId: string, mediaId: string): Promise<void> { 
    const token = await getAuthToken([mediaApiScope]);
    const resp = await axios.delete(
        `${mediaApiUri}/User/TeamMemberHero/${clientId}/${accountId}/${mediaId}`,
        { headers: { 
            'authorization': `bearer ${token}`,
			'cspace-appId': `${appId}`  
        } });
    return resp.data;
}

